<template>
 <div id="">
  <div class="">
   <PbxDashboard
    v-model:selector="periodSelector"
    :dataSeriesCurrentMonth="
     is_default ? dataSeriesCurrentMonth : dataSeriesCurrentMonthTop
    "
    @button-selector="(...args) => getSelectedButton(...args)"
    :extensions="extensions"
    :dataSeriesStacked="dataSeriesStacked"
    :urlType="urlType"
    :dataSeriesConcatenated="
     is_default ? dataSeriesConcatenated : dataSeriesConcatenatedTop
    "
    :periodSelector="this.periodSelector"
    @timeSelector="setTimeSelected($event)"
    @all-selected="allSelected($event)"
    :buttonSelected="this.buttonSelected"
    :textMonth="this.textMonth"
    :timeSelected="this.timeSelected"
    @is_default="is_default = $event"
    :startDate="startDate"
    :endDate="endDate"
    @setLoading="$emit('setLoading', $event)" />
   <!--   <div v-show="extensions.length === 0">
    <EmptyStateDashboard />
   </div> -->
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

const elementTypes = {
 0: "extension",
 1: "world",
 2: "undefined (2)",
 4: "queue",
 5: "voicemail",
 6: "ivr",
 8: "undefined (8)",
 9: "undefined (9)",
 12: "undefined (12)",
 13: "external_number_no_success",
 14: "call_flow",
 999: "not provided",
};

export default {
 props: ["presentationKey"],
 components: {
  EmptyStateDashboard,
  PbxDashboard,
 },
 data() {
  return {
   is_default: true,
   elementTypes,
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataSeriesCurrentMonth: [],
   dataSeriesCurrentMonthTop: [],
   extensions: [],
   dataSeriesStacked: [],
   dataSeriesConcatenated: [],
   dataSeriesStackedTop: [],
   dataSeriesConcatenatedTop: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: this.$t("dashboards.month"),
   textMonth: "",
   data: undefined,
   startDate: null,
   endDate: null,
   pbxMap: null,
  };
 },
 methods: {
  setPeriod(...args) {
   this.startDate = args[0];
   this.endDate = args[1];
   this.is_default ? this.getDataFromElement() : this.getDataFromElementTop10();
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  formatPeriod(start, end) {
   return start.split(" ")[0] + "-" + end.split(" ")[0];
  },
  async getPbxData(key) {
   try {
    const res = await axios.get(
     `https://xp-stats-101.bluerock.tel/api/v1.2/reports/${key}`
    );
    console.log(res.data);
    return res.data;
   } catch (error) {
    this.errorHandling(error);
    this.$emit("setLoading", false);
    this.$emit("error404", error.response);
   }
  },
  async getDataFromElement(key) {
   this.$emit("setLoading", true);

   this.dataSeriesCurrentMonth = [];

   let period = undefined;
   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";
   this.extensions.length = 0;
   let res = undefined;
   let obj = {};

   res = await this.getPbxData(key);

   let parameters = res.parameters;

   this.startDate = parameters?.start;
   this.endDate = parameters?.end;

   this.$store.dispatch("hostName", parameters.host_name);

   this.$store.dispatch("dataSet", parameters.report_type);

   //  this.$store.dispatch("hostTimeZone", parameters.timezone ? parameters.timezone : 0);

   this.urlType = parameters.element_type == 4 ? "queue" : "extension";
   this.periodSelector = this.formatPeriod(parameters.start, parameters.end);

   let timeSelected = [];

   if (parameters.time) {
    let splitTime = parameters.time.split(":");
    timeSelected[0] = splitTime[0];
    timeSelected[1] = splitTime[1];
   }

   this.timeSelected = timeSelected;
   if (this.dataSet === "origins") {
    stringOfElements = parameters.dns;
    type = parameters.type;
    period = this.formatPeriod(parameters.start, parameters.end);

    arrayOfElements = stringOfElements.split(",");

    let resData = res.data;

    let keys = [];
    let objectKeyToGet = "";
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    obj["data"] = [];

    if (timeSelected.length > 0) {
     if (
      timeSelected[0].slice(-2) === "00" &&
      timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
    }
    if (timeSelectedIsHour) {
     keys = Object.keys(resData.sum_quarter_hourly);
     objectKeyToGet = "sum_quarter_hourly";
    } else if (timeSelectedIsQuarter) {
     keys = Object.keys(resData.sum_quarter_hourly);
     objectKeyToGet = "sum_quarter_hourly";
    } else {
     if (period.length == 6 || period.includes("-")) {
      keys = Object.keys(resData.daily);
      objectKeyToGet = "daily";
     } else {
      keys = Object.keys(resData.daily);
      objectKeyToGet = "daily";
     }
    }

    if (keys.length > 0) {
     let dataObj = {};
     let dataObjSerie = {};
     let dataObjColor = {};

     const originElements = this.getAllOriginElementsFromKey(keys);

     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      let elementFromArray = arrayOfElements[arrIndex];

      obj = {};
      dataObj = {};

      let elementHasKey = false;

      for (let index = 0; index < keys.length; index++) {
       let splitElement = keys[index].split(":");
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        elementHasKey = true;
       }
      }
      if (elementHasKey) {
       if (objectKeyToGet === "daily") {
        obj = this.generateDataForPeriodCharts(
         keys,
         resData,
         elementFromArray,
         objectKeyToGet,
         originElements
        );
       } else if (objectKeyToGet === "sum_hourly") {
        obj = this.generateDataForHourlyCharts(
         keys,
         resData,
         elementFromArray,
         objectKeyToGet,
         originElements
        );
       } else if (objectKeyToGet === "sum_quarter_hourly") {
        obj = this.generateDataForQuarterHourlyCharts(
         keys,
         resData,
         elementFromArray,
         objectKeyToGet,
         originElements
        );
       } else {
        obj = this.generateDataForAllCharts(
         keys,
         resData,
         elementFromArray,
         objectKeyToGet,
         period
        );
       }
      } else {
       dataObjColor = "#5B9998";
       dataObjSerie = 0;
       dataObj["No_data"] = { dataObjSerie, dataObjColor };
       obj["data"] = dataObj;
       let elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      }
      this.dataSeriesCurrentMonth.push(obj);
      this.extensions.splice(arrIndex, 1, obj["name"]);
     }
     this.generateConsolidatedData(
      Object.keys(resData["daily*"]),
      resData,
      objectKeyToGet,
      period,
      originElements
     );
    } else {
     let dataObj = {};
     let dataObjSerie = {};
     let dataObjColor = {};

     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      dataObjColor = "#5B9998";
      dataObjSerie = 0;
      dataObj["No_data"] = { dataObjSerie, dataObjColor };
      obj["data"] = dataObj;
      let elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];
      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataSeriesCurrentMonth.push(obj);
     }
     this.dataSeriesConcatenated = [];
     obj = {};
     dataObjColor = "#5B9998";
     dataObjSerie = 0;
     dataObj["No_data"] = { dataObjSerie, dataObjColor };
     obj["data"] = dataObj;
     obj["name"] = "cons";
     this.dataSeriesConcatenated.push(obj);
    }
    this.$emit("setLoading", false);
   } else {
    this.dataSeriesCurrentMonth.length = 0;
    this.dataSeriesConcatenated.length = 0;
   }
   this.$emit("setLoading", false);
  },
  generateDataForQuarterHourlyCharts(
   keys,
   res,
   elementFromArray,
   objectKeyToGet,
   originElements
  ) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   let elementValue = 0;

   let firsQuarterHour =
    (
     "0" +
     (parseInt(this.timeSelected[0].slice(0, 2)) + parseInt(this.hostTimeZone))
    ).slice(-2) + this.timeSelected[0].slice(-2);
   let lastQuarterHour =
    (
     "0" +
     (parseInt(this.timeSelected[1].slice(0, 2)) + parseInt(this.hostTimeZone))
    ).slice(-2) + this.timeSelected[1].slice(-2);

   for (let index = 0; index < originElements.length; index++) {
    elementValue = 0;
    const originElement = originElements[index];
    keys.forEach((element, index) => {
     let splitElement = element.split(":");
     let elementName = "";
     elementName =
      this.urlType === "queue"
       ? res.map[splitElement[2]]?.name
       : res.map[splitElement[2]];
     if (splitElement[2] == elementFromArray) {
      let quarterHourlyElementSlice = splitElement[0].slice(-4);
      obj["name"] = splitElement[2] + "-" + elementName;
      if (splitElement[3] === originElement) {
       let type = this.elementTypes[splitElement[3]];
       if (
        parseInt(firsQuarterHour) <= parseInt(quarterHourlyElementSlice) &&
        parseInt(quarterHourlyElementSlice) < parseInt(lastQuarterHour)
       ) {
        elementValue = elementValue + parseInt(res[objectKeyToGet][element]);
        dataObjColor = this.getElementColor(type);

        dataObjSerie = elementValue;
        dataObj[type] = {
         dataObjSerie,
         dataObjColor,
        };
       }
       obj["data"] = dataObj;
      }
     }
    });
   }
   return obj;
  },
  generateDataForHourlyCharts(
   keys,
   res,
   elementFromArray,
   objectKeyToGet,
   originElements
  ) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   let elementValue = 0;
   let rangeOfHours = [];
   let rangeOfHoursLenght =
    parseInt(this.timeSelected[1].slice(0, 2)) -
    parseInt(this.timeSelected[0].slice(0, 2));
   let firsHour = (
    "0" +
    (parseInt(this.timeSelected[0].slice(0, 2)) + parseInt(this.hostTimeZone))
   ).slice(-2);

   for (let index = 0; index <= rangeOfHoursLenght; index++) {
    rangeOfHours.push(("0" + (parseInt(firsHour) + index)).slice(-2));
   }

   for (let index = 0; index < originElements.length; index++) {
    elementValue = 0;
    const originElement = originElements[index];
    keys.forEach((element, index) => {
     let splitElement = element.split(":");
     let elementName = "";
     elementName =
      this.urlType === "queue"
       ? res.map[splitElement[2]]?.name
       : res.map[splitElement[2]];
     //  elementName = res.map[splitElement[2]];
     if (splitElement[2] == elementFromArray) {
      obj["name"] = splitElement[2] + "-" + elementName;
      if (splitElement[3] === originElement) {
       let type = this.elementTypes[splitElement[3]];

       if (
        parseInt(rangeOfHours[0]) <= parseInt(splitElement[0].slice(-2)) &&
        parseInt(splitElement[0].slice(-2)) <
         parseInt(rangeOfHours[rangeOfHours.length - 1])
       ) {
        elementValue = elementValue + parseInt(res[objectKeyToGet][element]);
        dataObjColor = this.getElementColor(type);

        dataObjSerie = elementValue;
        dataObj[type] = {
         dataObjSerie,
         dataObjColor,
        };
       }
       obj["data"] = dataObj;
      }
     }
    });
   }
   return obj;
  },
  generateDataForPeriodCharts(
   keys,
   res,
   elementFromArray,
   objectKeyToGet,
   originElements
  ) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   let elementValue = 0;

   for (let index = 0; index < originElements.length; index++) {
    elementValue = 0;
    const originElement = originElements[index];
    keys.forEach((element, index) => {
     let splitElement = element.split(":");
     let elementName = "";
     elementName =
      this.urlType === "queue"
       ? res.map[splitElement[2]]?.name
       : res.map[splitElement[2]];
     //  elementName = res.map[splitElement[2]];
     if (splitElement[2] == elementFromArray) {
      obj["name"] = splitElement[2] + "-" + elementName;
      if (splitElement[3] === originElement) {
       let type = this.elementTypes[splitElement[3]];
       elementValue = elementValue + parseInt(res[objectKeyToGet][element]);
       dataObjColor = this.getElementColor(type);
       dataObjSerie = elementValue;
       dataObj[type] = {
        dataObjSerie,
        dataObjColor,
       };
       obj["data"] = dataObj;
      }
     }
    });
   }
   return obj;
  },
  generateDataForAllCharts(
   keys,
   res,
   elementFromArray,
   objectKeyToGet,
   period
  ) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   keys.forEach((element, index) => {
    let splitElement = element.split(":");
    let elementName = "";
    elementName =
     this.urlType === "queue"
      ? res.map[splitElement[2]]?.name
      : res.map[splitElement[2]];
    // elementName = res.map[splitElement[2]];
    if (splitElement[2] === period) {
     if (splitElement[3] == elementFromArray) {
      obj["name"] = splitElement[1] + " - " + elementName;

      dataObjColor = this.getElementColor(splitElement[3]);

      dataObjSerie = res[objectKeyToGet][element];

      dataObj[splitElement[3]] = {
       dataObjSerie,
       dataObjColor,
      };
      obj["data"] = dataObj;
     }
    }
   });
   return obj;
  },
  generateConsolidatedData(keys, res, objectKeyToGet, period, originElements) {
   this.dataSeriesConcatenated = [];

   let objConcat = {};

   let objToGetConcat = "daily*";

   let elementFromArray = "*";

   if (objectKeyToGet === "daily") {
    objConcat = this.generateDataForPeriodCharts(
     keys,
     res,
     elementFromArray,
     objToGetConcat,
     originElements
    );
   } else if (objectKeyToGet === "sum_hourly") {
    objConcat = this.generateDataForHourlyCharts(
     keys,
     res,
     elementFromArray,
     objToGetConcat,
     originElements
    );
   } else if (objectKeyToGet === "sum_quarter_hourly") {
    objConcat = this.generateDataForQuarterHourlyCharts(
     keys,
     res,
     elementFromArray,
     objToGetConcat,
     originElements
    );
   } else {
    objConcat = this.generateDataForAllCharts(
     keys,
     res,
     elementFromArray,
     objToGetConcat,
     period
    );
   }

   this.dataSeriesConcatenated.push(objConcat);
  },
  getAllOriginElementsFromKey(keys) {
   let arrayOfElements = [];
   keys.forEach((element, index) => {
    let splitEl = element.split(":");
    arrayOfElements.push(splitEl[3]);
   });
   arrayOfElements = [...new Set(arrayOfElements)];
   return arrayOfElements;
  },
  getElementColor(type) {
   let color = "";
   if (type === "queue") {
    color = "#97BAA6";
   } else if (type === "extension") {
    color = "#14233C";
   } else if (type === "world") {
    color = "#E34B5F";
   } else if (type === "ivr") {
    color = "#194E63";
   } else if (type === "external_number_success") {
    color = "#1E7889";
   } else if (type === "call_flow") {
    color = "#D9B3A3";
   } else if (type === "voicemail") {
    color = "#EFB0A1";
   }
   return color;
  },
  getElementsType(data) {
   let array = [];
   let keys = Object.keys(data);
   keys.forEach((element, index) => {
    let splitElement = element.split(":");
    if (!array.includes(splitElement[1])) {
     array.push(splitElement[1]);
    }
   });
   return array;
  },
  async getDataFromElementTop10(key) {
   this.$emit("setLoading", true);

   let res = undefined;

   res = await this.getPbxData(key);
   console.log("getDataFromElementTop10 ~ res:", res);
   this.pbxMap =
    res?.map && res.map?.original ? JSON.parse(res.map?.original) : null;
   let parameters = res.parameters;

   this.$store.dispatch("hostName", parameters.host_name);

   this.$store.dispatch("dataSet", parameters.report_type);

   //  this.$store.dispatch("hostTimeZone", parameters.timezone ? parameters.timezone : 0);

   this.urlType = parameters.element_type == 4 ? "queue" : "extension";
   this.periodSelector = this.formatPeriod(parameters.start, parameters.end);

   this.dataSeriesCurrentMonthTop = [];
   let period = this.formatPeriod(parameters.start, parameters.end);

   let arrayOfElements = [];
   let stringOfElements = parameters.dns;
   this.extensions.length = 0;

   let timeSelected = [];

   if (parameters.time) {
    let splitTime = parameters.time.split(":");
    timeSelected[0] = splitTime[0];
    timeSelected[1] = splitTime[1];
   }

   if (this.dataSet === "origins") {
    this.timeSelected = timeSelected;

    arrayOfElements = stringOfElements.split(",");

    let obj = {};
    let objConcat = {};
    let dataObj = {};
    let dataObjSerie = [];

    this.data = res.data;

    this.dataSeriesCurrentMonthTop = [];
    let keys = [];
    let objectKeyToGet = "";
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    obj["data"] = [];

    if (this.timeSelected.length > 0) {
     if (
      this.timeSelected[0].slice(-2) === "00" &&
      this.timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
    }

    if (timeSelectedIsHour) {
     keys = Object.keys(res.data.sum_quarter_hourly);
     objectKeyToGet = "sum_quarter_hourly";
    } else if (timeSelectedIsQuarter) {
     keys = Object.keys(res.data.sum_quarter_hourly);
     objectKeyToGet = "sum_quarter_hourly";
    } else {
     keys = Object.keys(res.data.daily);
     objectKeyToGet = "daily";
    }

    if (keys.length > 0) {
     let dataObj = {};
     let dataObjSerie = {};

     const originElements = this.getAllOriginElementsFromKeyTop10(keys);

     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      let elementFromArray = arrayOfElements[arrIndex];

      obj = {};
      dataObj = {};

      let elementHasKey = false;

      for (let index = 0; index < keys.length; index++) {
       let splitElement = keys[index].split(":");
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        elementHasKey = true;
       }
      }
      if (elementHasKey) {
       let top10Array = this.getTop10(
        keys,
        objectKeyToGet,
        arrayOfElements[arrIndex],
        res.data,
        originElements
       );

       if (objectKeyToGet === "sum_quarter_hourly") {
        top10Array = this.getTop10QuarterHour(
         keys,
         "all",
         arrayOfElements[arrIndex],
         res.data,
         originElements
        );
       }

       if (objectKeyToGet === "sum_hourly") {
        obj = this.generateDataForHourlyChartsTop(
         top10Array,
         elementFromArray,
         res.data
        );
       } else if (objectKeyToGet === "sum_quarter_hourly") {
        obj = this.generateDataForHourlyChartsTop(
         top10Array,
         elementFromArray,
         res.data
        );
       } else if (objectKeyToGet === "daily") {
        obj = this.generateDataForPeriodChartsTop10(
         top10Array,
         elementFromArray,
         res.data
        );
       } else {
        obj = this.generateDataForAllChartsTop10(
         top10Array,
         res.data,
         elementFromArray,
         objectKeyToGet,
         period
        );
       }
      } else {
       dataObjSerie = 0;
       dataObj["No_data"] = { dataObjSerie };
       obj["data"] = dataObj;
       let elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      }
      this.dataSeriesCurrentMonthTop.push(obj);
      this.extensions.splice(arrIndex, 1, obj["name"]);
     }

     this.dataSeriesConcatenatedTop = [];
     objConcat = {};

     let consObjectKeyToGet = objectKeyToGet + "*";
     let top10ArrayCons = this.getTop10(
      keys,
      consObjectKeyToGet,
      "*",
      res.data,
      originElements
     );

     if (objectKeyToGet === "sum_hourly") {
      top10ArrayCons = this.getTop10Hour(
       keys,
       consObjectKeyToGet,
       "*",
       res.data,
       originElements
      );
     } else if (objectKeyToGet === "sum_quarter_hourly") {
      top10ArrayCons = this.getTop10QuarterHour(
       keys,
       "all*",
       "*",
       res.data,
       originElements
      );
     }
     this.generateConsolidatedDataTop10(
      top10ArrayCons,
      res.data,
      consObjectKeyToGet,
      period
     );
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      dataObjSerie = 0;
      dataObj["No_data"] = { dataObjSerie };
      obj["data"] = dataObj;
      let elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];
      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataSeriesCurrentMonthTop.push(obj);
     }
     this.dataSeriesConcatenatedTop = [];
     obj = {};
     dataObjSerie = 0;
     dataObj["No_data"] = { dataObjSerie };
     obj["data"] = dataObj;
     obj["name"] = "cons";
     this.dataSeriesConcatenatedTop.push(obj);
    }
   }
   this.$emit("setLoading", false);
  },
  getTop10(keys, objectKeyToGet, element, data, originElements) {
   let array = [];
   let obj = {};
   let dataObj = {};
   let elementValue = 0;

   for (let index = 0; index < originElements?.length; index++) {
    elementValue = 0;
    const originElement = originElements[index];
    for (var el in data[objectKeyToGet]) {
     let splitEl = el.split(":");
     if (splitEl[2] == element) {
      if (splitEl[4] === originElement) {
       let name = undefined;
       if (
        this.pbxMap &&
        (this.elementTypes[splitEl[3]] === "queue" ||
         this.elementTypes[splitEl[3]] === "extension")
       ) {
        if (this.elementTypes[splitEl[3]] === "extension") {
         name = this.pbxMap["extensions"][originElement];
        } else {
         name = this.pbxMap["call_queues"][originElement]["name"];
        }
       }
       elementValue = elementValue + parseInt(data[objectKeyToGet][el]);
       dataObj[
        `${this.elementTypes[splitEl[3]]}-${splitEl[4]}${
         name ? "-" + name : ""
        }`
       ] = elementValue;
       obj["data"] = dataObj;
      }
     }
    }
   }

   if (obj && obj.data && Object.keys(obj.data)) {
    Object.keys(obj.data).forEach(el => {
     array.push([el, obj.data[el]]);
    });

    array.sort(function (a, b) {
     return b[1] - a[1];
    });
   }

   array = array.slice(0, 10);

   return array;
  },
  getTop10QuarterHour(keys, objectKeyToGet, element, data, originElements) {
   let array = [];
   let obj = {};
   let dataObj = {};
   let elementValue = 0;
   let firsQuarterHour =
    (
     "0" +
     (parseInt(this.timeSelected[0].slice(0, 2)) + parseInt(this.hostTimeZone))
    ).slice(-2) + this.timeSelected[0].slice(-2);
   let lastQuarterHour =
    (
     "0" +
     (parseInt(this.timeSelected[1].slice(0, 2)) + parseInt(this.hostTimeZone))
    ).slice(-2) + this.timeSelected[1].slice(-2);

   for (let index = 0; index < originElements.length; index++) {
    elementValue = 0;
    const originElement = originElements[index];
    for (var el in data[objectKeyToGet]) {
     let splitEl = el.split(":");
     if (splitEl[2] == element && splitEl[0].length == 12) {
      let quarterHourlyElementSlice = splitEl[0].slice(-4);
      if (splitEl[4] === originElement) {
       if (
        parseInt(firsQuarterHour) <= parseInt(quarterHourlyElementSlice) &&
        parseInt(quarterHourlyElementSlice) < parseInt(lastQuarterHour)
       ) {
        elementValue = elementValue + parseInt(data[objectKeyToGet][el]);
        dataObj[`${this.elementTypes[splitEl[3]]}-${splitEl[4]}`] =
         elementValue;
       }
       obj["data"] = dataObj;
      }
     }
    }
   }

   if (obj && obj.data && Object.keys(obj.data)) {
    Object.keys(obj.data).forEach(el => {
     array.push([el, obj.data[el]]);
    });

    array.sort(function (a, b) {
     return b[1] - a[1];
    });
   }

   array = array.slice(0, 10);

   return array;
  },
  generateDataForHourlyChartsTop(arr, elementFromArray, data) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let elementName =
    this.urlType === "queue"
     ? data.map[elementFromArray]?.name
     : data.map[elementFromArray];
   obj["name"] = elementFromArray + " - " + elementName;
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    dataObjSerie = element[1];
    dataObj[element[0]] = { dataObjSerie };
    obj["data"] = dataObj;
   }
   return obj;
  },
  generateDataForPeriodChartsTop10(arr, elementFromArray, data) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let elementName =
    this.urlType === "queue"
     ? data.map[elementFromArray]?.name
     : data.map[elementFromArray];
   obj["name"] = elementFromArray + " - " + elementName;
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];

    dataObjSerie = element[1];
    dataObj[element[0]] = { dataObjSerie };
    obj["data"] = dataObj;
   }
   return obj;
  },
  generateDataForAllChartsTop10(
   keys,
   res,
   elementFromArray,
   objectKeyToGet,
   period
  ) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   keys.forEach((element, index) => {
    let splitElement = element[0].split(":");
    let label = splitElement[3] + "-" + splitElement[4];
    let elementName = "";
    elementName = res.map[splitElement[1]];
    if (splitElement[2] === period) {
     if (splitElement[1] == elementFromArray) {
      obj["name"] = splitElement[1] + " - " + elementName;
      dataObjSerie = res[objectKeyToGet][element[0]];
      dataObj[label] = {
       dataObjSerie,
      };
      obj["data"] = dataObj;
     }
    }
   });
   return obj;
  },
  generateConsolidatedDataTop10(keys, res, objectKeyToGet, period) {
   this.dataSeriesConcatenatedTop = [];
   let objConcat = {};
   let elementFromArray = "*";
   if (objectKeyToGet === "sum_hourly*") {
    objConcat = this.generateDataForHourlyChartsTop(
     keys,
     elementFromArray,
     res
    );
   } else if (objectKeyToGet === "sum_quarter_hourly*") {
    objConcat = this.generateDataForHourlyChartsTop(
     keys,
     elementFromArray,
     res
    );
   } else if (objectKeyToGet === "daily*") {
    objConcat = this.generateDataForPeriodChartsTop10(
     keys,
     elementFromArray,
     res
    );
   } else {
    objConcat = this.generateDataForAllChartsTop10(
     keys,
     res,
     elementFromArray,
     objectKeyToGet,
     period
    );
   }
   this.dataSeriesConcatenatedTop.push(objConcat);
  },
  getAllOriginElementsFromKeyTop10(keys) {
   let arrayOfElements = [];
   keys.forEach((element, index) => {
    let splitEl = element.split(":");
    arrayOfElements.push(splitEl[4]);
   });
   arrayOfElements = [...new Set(arrayOfElements)];
   return arrayOfElements;
  },
 },
 mounted() {
  this.getDataFromElement(this.presentationKey);
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostTimeZone",
   "dataSet",
  ]),
 },
 watch: {
  is_default: function (val) {
   if (val) {
    this.getDataFromElement(this.presentationKey);
   } else {
    this.getDataFromElementTop10(this.presentationKey);
   }
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
